import { functions } from "../../../../../utils/firebase";
import { FirestoreCollectionName } from "../../../types/FirestoreCollectionName";

export const downloadCollection = async (
  collection: FirestoreCollectionName
) => {
  try {
    const response = await fetch(
      "http://35.245.183.145:8080/downloadCollection",
      {
        method: "POST", // Specify the method (POST, PUT, PATCH, etc.)
        headers: {
          "Content-Type": "application/json", // Ensure the content type is set for JSON
        },
        body: JSON.stringify({
          collectionName: collection,
        }), // Replace this with your data
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const json: { url: string } = await response.json();
    window.open(json.url, "_blank");
  } catch (error) {
    throw error;
  }

  // const downloadCollectionFunction = functions.httpsCallable(
  //   "downloadCollection",
  //   { timeout: 540000 }
  // );
  // try {
  //   const result = await downloadCollectionFunction({
  //     collectionName: collection,
  //   });
  //   console.log("Download URL:", result.data.url);
  //   window.open(result.data.url, "_blank");
  // } catch (error) {
  //   console.error("Error calling Firebase function:", error);
  //   throw error;
  // }
};
